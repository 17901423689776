export {
  projects
}
let projects=[
  {
    image:require('@/assets/project/answer1.jpg'),
    name:'就餐区域',
    id:101
  },
  {
    image:require('@/assets/project/answer2.jpg'),
    name:'自习室',
    di:102
  },
  {
    image:require('@/assets/project/answer3.jpg'),
    name:'健身房',
    id:103
  },
  {
    image:require('@/assets/project/answer4.jpg'),
    name:'食堂',
    id:104
  },
  {
    image:require('@/assets/project/answer5.jpg'),
    name:'宿舍',
    id:105
  },
  {
    image:require('@/assets/project/answer6.jpg'),
    name:'前台',
    id:106
  },
]
